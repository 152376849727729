import React from "react"
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import {injectIntl} from "gatsby-plugin-react-intl"
import SecurityData from "../../content/security/index.json";
import HeroPagesCenter from "../../components/heroes/hero-pages-center";
import {Container} from "react-bootstrap";
import SimpleBlock from "../../components/misc/simple-block";
import MultiColBlock from "../../components/misc/multi-col-block";

const SecurityIndexPage = ({ intl, location }) => (
    <Layout>
        <Seo lang={intl.locale}
             title={SecurityData[intl.locale].title}
             description={SecurityData[intl.locale].text}
             pathname={location.pathname}
        />
        <HeroPagesCenter
            theme={`primary`}
            content={SecurityData[intl.locale]}
        />
        <Container>
        {SecurityData[intl.locale].reasons.map((item, i) => {

            return (
                <SimpleBlock key={i} content={item} />
            )

        })}
        </Container>
        <div className={`bg-light`}>
            <Container>
                <MultiColBlock content={SecurityData[intl.locale].callout} />
            </Container>
        </div>
    </Layout>
)

export default injectIntl(SecurityIndexPage)
